/**
 * App Global Styles, Themes, Constants go below
 */

export const APP_PLATFORM = {
  APP_NAME: "IA Smart Platform",
};

export const POSTHOG_KEY = "52vkz2GHhslnKOeUfI2Vnmq3ls6KJeTWWJiAQ6SCFAg";

/**
 * Constants for the App Attribute Smart are defined below
 */
export const APP_ATTRIBUTE_SMART = {
  APP_NAME: "AttributeSmart",
  ATTRIBUTE_SMART_SAMPLE_CSV:
    "https://storage.googleapis.com/generic-attribute-smart-public-test/sample_csv/dsg_sample.csv",
};

export const APP_MARKSMART = {
  APP_NAME: "MarkSmart",
};

/**
 * Constants for Field Types
 */
export const TEXT_FIELDS = ["str", "CharField", "int", "list"];
export const TEXT_FIELDS_SETALL = ["str", "CharField", "int"];
export const NUMERIC_FIELDS = ["int", "IntegerField"];

/**
 * constants for Filters Screen are defined below
 */
export const FILTER_TABS = [
  { label: "Product Filters", id: "product" },
  { label: "Store Filters", id: "store" },
  { label: "Others", id: "others" },
];
export const FILTER_DIMENSIONS = {
  product: [
    { label: "Ticket", value: 87 },
    { label: "Subticket", value: 88 },
  ],
  store: [
    { label: "Ticket", value: 87 },
    { label: "Subticket1", value: 89 },
  ],
  others: [
    { label: "Ticket", value: 87 },
    { label: "Subticket2", value: 90 },
  ],
};
export const FILTER_MODULES = {
  product: [
    {
      label: "Reporting",
      id: 1,
    },
    {
      label: "Admin",
      id: 2,
    },
    {
      label: "Notifications",
      id: 3,
    },
  ],
  store: [
    {
      label: "Reporting",
      id: 1,
    },
    {
      label: "Admin",
      id: 2,
    },
    {
      label: "Notifications",
      id: 3,
    },
  ],
  others: [
    {
      label: "Reporting",
      id: 1,
    },
    {
      label: "Admin",
      id: 2,
    },
    {
      label: "Notifications",
      id: 3,
    },
  ],
};

export const FUNCTIONALITIES = [
  // { label: "Text", value:  },
  // { label: "Integer", value: 1 },
  { label: "Dropdown", value: "dropdown" },
  // { label: "DateTime", value: 3 },
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  // { label: "Boolean", value: 6 },
];

export const FILTER_TYPES = [
  { label: "Cascaded", value: "cascaded" },
  { label: "NonCascaded", value: "non-cascaded" },
];

export const UNIT_TYPES = [
  {
    label: "Eaches",
    value: "eaches",
  },
  {
    label: "Packs",
    value: "packs",
  },
  {
    label: "Cartons",
    value: "cartons",
  },
  {
    label: "Boxes",
    value: "boxes",
  },
];

export const UNIT_DEFN_ATTRIBUTE_TYPES = [
  {
    label: "Single Size - All Colors",
    value: "single size - all colors",
  },
  {
    label: "All Size - Single Color",
    value: "all size - single color",
  },
  {
    label: "Different Size - Different Color",
    value: "different size - different color",
  },
];
export const MANUAL_GROUP_TYPE_FILTERS = [
  {
    label: "Product Hierarchy",
    value: "product_hierarchy",
  },
  {
    label: "Grouping Definitions",
    value: "grouping_definitions",
  },
];
export const END_DATE = "2099-12-31";

export const ReactTableFeatures = {
  CHECKBOX_LABEL_FOR_HEADER: true,
};
export const STORE_STATUS_FILTER = {
  column_name: "status",
  default_value: null,
  dimension: "store",
  display_type: "dropdown",
  fc_code: 100,
  filter_keyword: "status",
  is_mandatory: false,
  is_multiple_selection: false,
  label: "Store Status",
  level: 2,
  type: "non-cascaded",
  initialData: [
    { label: "Open", id: "open", value: "open" },
    { label: "Deactivated", id: "deactivated", value: "deactivated" },
    { label: "Close", id: "close", value: "close" },
    { label: "Renovation", id: "renovation", value: "renovation" },
  ],
};

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 5;


export const STATUSES = {
  "1": 'Open',
  "2": 'Unresolved',
  "3": 'Monitoring',
  // "5": 'Closed',
  "5": 'No Issue',
  "4":'Resolved',
  
}
